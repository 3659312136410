import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-1bd58f39"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "text-center mb-10" };
var _hoisted_2 = {
    key: 0,
    class: "text-gray-400 fw-bold fs-4"
};
var _hoisted_3 = { class: "text-gray-400 fw-bold fs-4" };
var _hoisted_4 = { class: "fv-row mb-10" };
var _hoisted_5 = { class: "form-label fw-bolder text-gray-900 fs-6" };
var _hoisted_6 = { class: "d-flex flex-wrap justify-content-center pb-lg-0" };
var _hoisted_7 = {
    key: 1,
    class: "form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
};
var _hoisted_8 = { class: "text-center mb-10" };
var _hoisted_9 = { class: "text-gray-400 fw-bold fs-4" };
var _hoisted_10 = { class: "text-gray-400 fw-bold send_message" };
var _hoisted_11 = {
    key: 0,
    class: "text-gray-400 fw-bold fs-4"
};
var _hoisted_12 = { class: "fv-row mb-10" };
var _hoisted_13 = {
    key: 0,
    class: "invalid-feedback d-block"
};
var _hoisted_14 = {
    key: 1,
    class: "code_validate"
};
var _hoisted_15 = { key: 2 };
var _hoisted_16 = { class: "text-center text-muted text-uppercase fw-bolder my-5" };
var _hoisted_17 = { class: "d-flex flex-wrap justify-content-center pb-lg-0" };
var _hoisted_18 = { class: "text-primary" };
var _hoisted_19 = { class: "text-center text-muted text-uppercase fw-bolder my-5" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_router_link = _resolveComponent("router-link");
    var _component_base_field = _resolveComponent("base-field");
    var _component_base_button = _resolveComponent("base-button");
    var _component_Form = _resolveComponent("Form");
    var _component_base_spinner = _resolveComponent("base-spinner");
    var _component_base_social_login = _resolveComponent("base-social-login");
    var _component_base_col = _resolveComponent("base-col");
    return (_openBlock(), _createBlock(_component_base_col, { class: "bg-white rounded mx-auto max_w_375" }, {
        default: _withCtx(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return [
                (((_a = _ctx.showing) === null || _a === void 0 ? void 0 : _a.page) === 1)
                    ? (_openBlock(), _createBlock(_component_Form, {
                        key: 0,
                        class: "form w-100 fv-plugins-bootstrap5 fv-plugins-framework",
                        onSubmit: _ctx.page2,
                        id: "kt_login_password_reset_form",
                        "validation-schema": _ctx.formLoginValidate
                    }, {
                        default: _withCtx(function () { return [
                            _createElementVNode("div", _hoisted_1, [
                                true
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("Enter your phone number to login.")), 1))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_3, [
                                    _createTextVNode(_toDisplayString(_ctx.$t("Don't have an account?")) + " ", 1),
                                    _createVNode(_component_router_link, { to: { name: 'mobile-register' } }, {
                                        default: _withCtx(function () { return [
                                            _createTextVNode(_toDisplayString(_ctx.$t("Register")), 1)
                                        ]; }),
                                        _: 1
                                    })
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("Phone Number")), 1),
                                _createVNode(_component_base_field, {
                                    modelValue: _ctx.form.phone_number,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((_ctx.form.phone_number) = $event); }),
                                    name: "phone_number",
                                    type: "number",
                                    placeholder: _ctx.$t('Enter Phone Number'),
                                    autofocus: ""
                                }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_base_button, {
                                    showLoading: _ctx.loading.nextPage,
                                    class: "w-100"
                                }, {
                                    default: _withCtx(function () { return [
                                        _createTextVNode(_toDisplayString(_ctx.$t("Next")), 1)
                                    ]; }),
                                    _: 1
                                }, 8, ["showLoading"])
                            ])
                        ]; }),
                        _: 1
                    }, 8, ["onSubmit", "validation-schema"]))
                    : _createCommentVNode("", true),
                (((_b = _ctx.showing) === null || _b === void 0 ? void 0 : _b.page) === 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_9, " (+855) " + _toDisplayString((_c = _ctx.form) === null || _c === void 0 ? void 0 : _c.phone_number), 1),
                                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("We have sent you a message with the code")), 1),
                                (!((_d = _ctx.showing) === null || _d === void 0 ? void 0 : _d.isExpire))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                        _createTextVNode(_toDisplayString(_ctx.$t("Expired in")) + " ", 1),
                                        _createElementVNode("span", null, _toDisplayString(_ctx.displayTime), 1)
                                    ]))
                                    : _createCommentVNode("", true),
                                (((_e = _ctx.showing) === null || _e === void 0 ? void 0 : _e.isExpire) && ((_f = _ctx.showing) === null || _f === void 0 ? void 0 : _f.showResendOtp))
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 1,
                                        role: "button",
                                        class: "text-primary fw-bold fs-4",
                                        onClick: _cache[1] || (_cache[1] =
                                            //@ts-ignore
                                            function () {
                                                var args = [];
                                                for (var _i = 0; _i < arguments.length; _i++) {
                                                    args[_i] = arguments[_i];
                                                }
                                                return (_ctx.resendLoginOtp && _ctx.resendLoginOtp.apply(_ctx, args));
                                            })
                                    }, [
                                        _createTextVNode(_toDisplayString(_ctx.$t("Resend Otp?")) + " ", 1),
                                        _createVNode(_component_base_spinner, {
                                            class: "lh-0 me-1 top_8 text-success",
                                            loading: (_g = _ctx.loading) === null || _g === void 0 ? void 0 : _g.resend
                                        }, null, 8, ["loading"])
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_base_field, {
                                modelValue: _ctx.form.otp,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((_ctx.form.otp) = $event); }),
                                name: "otp",
                                type: "number",
                                onOnChange: _ctx.onChangeOtp,
                                placeholder: _ctx.$t('Your otp')
                            }, null, 8, ["modelValue", "onOnChange", "placeholder"]),
                            (((_h = _ctx.form) === null || _h === void 0 ? void 0 : _h.otp) && !_ctx.showing.validateCode)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("Invalid Code")), 1)
                                ]))
                                : _createCommentVNode("", true),
                            (_ctx.showing.validateCode)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("Code Validate")), 1)
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ]))
                    : _createCommentVNode("", true),
                ((_j = _ctx.showing) === null || _j === void 0 ? void 0 : _j.displayAnotherLogin)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("or")), 1),
                        _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("span", {
                                class: "h2 text-uppercase",
                                role: "button",
                                onClick: _cache[3] || (_cache[3] =
                                    //@ts-ignore
                                    function () {
                                        var args = [];
                                        for (var _i = 0; _i < arguments.length; _i++) {
                                            args[_i] = arguments[_i];
                                        }
                                        return (_ctx.gotoEmailLogin && _ctx.gotoEmailLogin.apply(_ctx, args));
                                    })
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t("Login by")) + " ", 1),
                                _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("Email")), 1)
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("or")), 1),
                        _createVNode(_component_base_social_login)
                    ]))
                    : _createCommentVNode("", true)
            ];
        }),
        _: 1
    }));
}
